import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ConsumableProductField } from '../../component';
import { FixMeLater } from 'src/types';

// TODO: add proper types.
interface Props {
  selectedWarranty: FixMeLater;
  setSelectedWarranty: FixMeLater;
  onWarrantyInputChange: FixMeLater;
  warrantyInput: FixMeLater;
  warranties: FixMeLater;
  productType: FixMeLater;
  consumableUnit: FixMeLater;
  onChangeProductType: FixMeLater;
  setConsumableUnit: FixMeLater;
  canCheckValidation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  selectedWarranty,
  setSelectedWarranty,
  onWarrantyInputChange,
  warrantyInput,
  warranties,
  productType,
  consumableUnit,
  onChangeProductType,
  setConsumableUnit,
  canCheckValidation
}: Props) => {
  const classes = useStyles();

  const [errFields, setErrFields] = useState({
    warranty: ''
  });

  const fieldValidations = [
    {
      field: 'warranty',
      value: selectedWarranty,
      errorMessage: 'WARRANTY IS REQUIRED'
    }
  ];

  const areFieldsValid = () => {
    fieldValidations.forEach((validation) => {
      const { field, value, errorMessage } = validation;
      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    });
  };

  const validateField = (name: string, value: any) => {
    const targetValidation = fieldValidations.find(
      (validation) => validation.field === name
    );
    if (targetValidation) {
      const { field, errorMessage } = targetValidation;
      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    }
  };

  useEffect(() => {
    if (canCheckValidation === true) {
      areFieldsValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarranty]);

  useEffect(() => {
    if (canCheckValidation === true) {
      areFieldsValid();
    } else {
      errFields.warranty = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCheckValidation]);

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Other Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Autocomplete
            freeSolo
            value={selectedWarranty}
            onChange={(_, newValue) => {
              setSelectedWarranty(newValue);
              validateField('warranty', newValue);
            }}
            inputValue={warrantyInput}
            onInputChange={(_, newInputValue) => {
              onWarrantyInputChange(newInputValue);
            }}
            id="warranty"
            options={warranties}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errFields?.warranty ? true : false}
                helperText={errFields?.warranty}
                label="Default Warranty Duration (days)"
                variant="outlined"
                name="warranty"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ConsumableProductField
            productType={productType}
            selectedConsumable={consumableUnit}
            onChangeProductType={onChangeProductType}
            setConsumableUnit={setConsumableUnit}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const AddProductOtherInfo = React.memo(component);
