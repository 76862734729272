import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isValid } from 'date-fns';
import { warranties } from 'src/constants';

export const currentYear = new Date().getFullYear();
//this will give options from 2023 and so on
export const yearOptions = Array.from(
  new Array(currentYear - 2023 + 1),
  (val, index) => 2023 + index
);

export const isDateValid = (dateOrString: any) => {
  if (dateOrString) {
    return false;
  }
  // if (dateOrString instanceof Date) {
  //   return true;
  // }
  return isValid(dateOrString);
};

export const dateToday = (formatStr: string = 'PPpp') => {
  const today = format(new Date(), formatStr);
  return today;
};

export const dateTodayVanilla = () => {
  const today = new Date();
  return today;
};

export const isDateMidnight = (dateOrStrParam: Date | string) => {
  if (dateOrStrParam instanceof Date) {
    const time = dateOrStrParam.toISOString().split('T')[1];
    if (time === '00:00:00.000Z') {
      return true;
    }
  }
  if (
    String(dateOrStrParam).match(
      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    ) !== null
  ) {
    return true;
  }
  const dateTimeISO = new Date(dateOrStrParam).toISOString().split('T')[1];
  if (dateTimeISO === '00:00:00.000Z') {
    return true;
  }

  return false;
};

export const formatDate = (
  dateOrStr?: Date | string | null,
  pattern = 'PP'
) => {
  if (!dateOrStr) {
    return 'Invalid Date';
  }
  if (dateOrStr === '0000-00-00 00:00:00') {
    return 'Invalid Date';
  }
  if (isDateMidnight(dateOrStr)) {
    const utcLocal = convertUTCtoLocal(dateOrStr);
    return `${format(utcLocal, pattern)}`;
  }
  return format(toDate(dateOrStr), pattern);
};

export const toUtcEquivalentDate = (date: Date | null) => {
  if (!date) {
    return undefined;
  }
  return new Date(format(date, "yyyy-MM-dd'T'00:00:00.000") + 'Z');
};

export const getWarrantyInDays = (val: string) => {
  if (val) {
    const daysValue = warranties.find((x) => x.name === val)?.value;
    // accept zero days as legit value
    if (daysValue !== undefined && daysValue >= 0) {
      return daysValue;
    }
    if (!isNaN(+val) && +val > 0) {
      return Math.round(+val);
    }
  }
  return undefined;
};

export const toDate = (date: Date | string): Date => {
  if (date instanceof Date) {
    return date;
  } else {
    return new Date(date);
  }
};

export const convertUTCtoLocal = (dateOrString: Date | string) => {
  const date = toDate(dateOrString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const muiDatePickerToDateStr = (date: MaterialUiPickersDate) => {
  return toUtcEquivalentDate(date)?.toISOString();
};

export const timeStampNow = () => +new Date();

export const transformerDateField = (date: MaterialUiPickersDate) => {
  const dateUtc = formatDate(date || '', 'yyyy-MM-dd');
  return dateUtc;
};
