import React, { useMemo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Transaction } from 'src/types';
import { RenderCurrency } from './RenderCurrency';
import { ViewWarrantyRow } from './ViewWarranty';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { cloneDeep } from 'lodash';
import { ProductTypeEnum } from 'src/enums';
import { warranties } from 'src/constants';

interface Props {
  items?: Transaction[];
  isBlankPage?: boolean;
  isLastPage?: boolean;
  kachiPoints?: number; // this is negative number
}

const styles = StyleSheet.create({
  main: {
    marginTop: 10,
    borderRightWidth: 1
  },
  header: {
    height: 20
  },
  headerColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  itemColumnContainer: {
    minHeight: 15
  },
  itemColumn: {
    // Adding padding of x-axis (horizontal) here would mis align borders. Do not waste time
    paddingVertical: 2,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  descriptionOrSnText: {
    paddingHorizontal: 5
  }
});

const flexSizeOfColumns = {
  qty: 0.5,
  description: 3,
  unitPrice: 1,
  amount: 1
};

const headerColumns = [
  { flex: flexSizeOfColumns.qty, title: 'Qty' },
  {
    flex: flexSizeOfColumns.description,
    title: 'Description / Serial No.'
  },
  {
    flex: flexSizeOfColumns.unitPrice,
    title: 'Unit Price'
  },
  { flex: flexSizeOfColumns.amount, title: 'Amount' }
];

//We need this string to display pts on receipt
const kachiPointsTitle = 'KACHI POINTS USED:';

type ItemPerLineData = {
  product_id?: number;
  quantity?: number;
  title?: string;
  retail_price?: number;
  amount?: number;
  hidden?: boolean;
  spend_kachi_points?: number;
};

export const RenderItemsTable = ({
  items,
  isBlankPage,
  kachiPoints,
  isLastPage
}: Props) => {
  const numberOfLinesOfItems = useMemo(() => {
    let actualRowsTaken = 0;
    const clonedTransactionItems = cloneDeep(items || []);
    clonedTransactionItems?.forEach((item) => {
      const itemName = item.product_name;
      if (itemName) {
        actualRowsTaken++;
      }
      if (
        item?.product_type !== ProductTypeEnum.Consumable &&
        item?.serial_nos &&
        item?.serial_nos?.length > 0
      ) {
        item?.serial_nos?.forEach((x) => {
          if (x) {
            actualRowsTaken++;
          }
        });
      }
      item.warranty_duration !== 0 && actualRowsTaken++; // count another line if item has warranty
    });
    isLastPage && kachiPoints !== 0 && actualRowsTaken++;
    actualRowsTaken++; // count a line for nothing follows row
    return actualRowsTaken;
  }, [isLastPage, items, kachiPoints]);

  const itemsWithNothingToFollow = useMemo(() => {
    const limitOfLinePerPage = 19;
    // lines is less than 19 add dummy data on items

    if (numberOfLinesOfItems < limitOfLinePerPage) {
      const numOfDummyLinesToAdd = limitOfLinePerPage - numberOfLinesOfItems;
      const clonedTransactionItems = cloneDeep(items);

      for (let i = 0; i <= numOfDummyLinesToAdd; i++) {
        if (i === 0) {
          clonedTransactionItems?.push({
            product_name:
              kachiPoints && kachiPoints < 0 ? kachiPointsTitle : '',
            retail_price: undefined,
            amount: Math.abs(kachiPoints || 0)
          });
          clonedTransactionItems?.push({
            product_name:
              isBlankPage || !isLastPage
                ? ' '
                : '-------- Nothing Follows --------'
          });
        } else {
          clonedTransactionItems?.push({
            product_name: '   '
          });
        }
      }
      return clonedTransactionItems;
    }
    return items;
  }, [isBlankPage, isLastPage, items, kachiPoints, numberOfLinesOfItems]);

  const itemsArrayOnPerLine = useMemo(() => {
    let newItemsArr: ItemPerLineData[] = [];
    const clonedItemsWithNothingFollows = cloneDeep(itemsWithNothingToFollow);

    clonedItemsWithNothingFollows?.forEach((x) => {
      if (x?.product_name) {
        newItemsArr?.push({
          product_id: x?.product_id,
          quantity: x?.quantity,
          title: x?.product_name,
          retail_price: x?.retail_price,
          amount: x?.amount,
          spend_kachi_points: x?.spend_kachi_points
        });
      }
      if (x?.serial_nos && x?.serial_nos?.length > 0) {
        x?.serial_nos?.forEach((sn) => newItemsArr?.push({ title: sn }));
      }
      if (x?.warranty_duration) {
        const warranyDuration = warranties?.find((warranty) => {
          return warranty?.value === x?.warranty_duration;
        });
        newItemsArr?.push({
          title: `WARRANTY: ${warranyDuration?.name}`
        });
      }
    });

    return newItemsArr;
  }, [itemsWithNothingToFollow]);

  const displayQuantity = (item: ItemPerLineData) => {
    // check if item has prod id. This means row is product and should display 1 qty as default.
    // this is needed for non sn items like internet cable. Cause they are outed as 1 item regardless of how many meters
    if (item.product_id) {
      return item.quantity || '1';
    }

    return item.quantity || '';
  };

  const ItemRow = ({ item }: { item: ItemPerLineData }) => {
    return (
      <ViewWarrantyRow
        style={{
          height: item?.title && item?.title?.length >= 40 ? 'auto' : 17
        }}
      >
        <View style={[styles.itemColumn, { flex: flexSizeOfColumns.qty }]}>
          <TextRegularWarranty>{displayQuantity(item)}</TextRegularWarranty>
        </View>
        <View
          style={[
            styles.itemColumn,
            { flex: flexSizeOfColumns.description, alignItems: 'flex-start' }
          ]}
        >
          <TextRegularWarranty style={styles.descriptionOrSnText}>
            {item?.title?.toUpperCase()}
          </TextRegularWarranty>
        </View>
        <View
          style={[styles.itemColumn, { flex: flexSizeOfColumns.unitPrice }]}
        >
          <TextRegularWarranty>
            {item?.title === kachiPointsTitle ? (
              item?.retail_price === undefined ? (
                '---'
              ) : (
                <>
                  {'-'}
                  <RenderCurrency amount={item?.retail_price || 0} />
                  {''}
                </>
              )
            ) : item?.retail_price === undefined ? null : (
              <RenderCurrency amount={item?.retail_price || 0} />
            )}
          </TextRegularWarranty>
        </View>
        <View style={[styles.itemColumn, { flex: flexSizeOfColumns.amount }]}>
          <TextRegularWarranty>
            {item?.title === kachiPointsTitle ? (
              item?.amount === undefined ? null : (
                <>
                  {'-'}
                  <RenderCurrency amount={item?.amount || 0} />
                  {''}
                </>
              )
            ) : item?.amount === undefined ? null : (
              <RenderCurrency amount={item?.amount || 0} />
            )}
          </TextRegularWarranty>
        </View>
      </ViewWarrantyRow>
    );
  };

  return (
    <View style={styles.main}>
      <ViewWarrantyRow style={styles.header}>
        {headerColumns.map((x) => (
          <View key={x.title} style={[styles.headerColumn, { flex: x.flex }]}>
            <TextBoldWarranty>{x.title}</TextBoldWarranty>
          </View>
        ))}
      </ViewWarrantyRow>
      {itemsArrayOnPerLine?.map((item, i) => (
        <ItemRow key={i} item={item} />
      ))}
    </View>
  );
};
