import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { FixMeLaterApi, Listing } from 'src/types';
import { formatCurrency } from 'src/utils';
import { Product } from 'src/types/product';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Category, Manufacturer } from 'src/constants/inventory';
import { usePermissions, useProductPartDetailsLogic } from 'src/hooks';
import { Page } from 'src/components';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { TransferStockListingDialog } from './ProductTransferListingDialog';

const { actions: categoryActions } = slices.category;
const { actions: manufacturerActions } = slices.manufacturer;

interface Props {
  product: Product;
  className?: string;
  listings?: Listing[];
  onDeleteProduct: (product: FixMeLaterApi) => void;
  onSaveDetailPress: (product: Product) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  addItemBtn: {
    color: 'white',
    marginBottom: '1em',
    background: '#ff9800',
    '&:hover': {
      backgroundColor: '#ff9800',
      color: 'white'
    },
    '&:active': {
      backgroundColor: '#ff9800',
      color: 'white'
    }
  }
}));

const ProductDetailView = ({
  className,
  listings,
  product,
  ...rest
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { resetProductPartsStates } = useProductPartDetailsLogic();
  const {
    transferCount,
    transferCountListing,
    getTransferCount,
    getTransferCountListings
  } = useStockTransfer();
  const {
    canAddListing,
    canEditProductInformation,
    viewTransferListingStock
  } = usePermissions();

  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getCategories = async () => {
    const getCategoriesResponse = unwrapResult(
      await dispatch(categoryActions.getCategoriesThunk({}))
    );
    if (getCategoriesResponse?.originalData?.data) {
      setCategories(getCategoriesResponse.originalData.data);
    }
  };

  const onAddListingItemPress = () => {
    const sampleSerialNos = listings
      ?.map((listing) => listing?.serial_no)
      ?.filter((serialNo) => serialNo)
      ?.splice(0, 3);

    navigate('/app/products-listings/add', {
      state: { ...product, sample_serial_nos: sampleSerialNos }
    });
  };

  const getManufacturers = async () => {
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturersThunk({}))
    );
    if (getManufacturersResponse?.originalData?.manufacturers) {
      setManufacturers(getManufacturersResponse.originalData.manufacturers);
    }
  };

  const onEditProductInformationPress = () => {
    navigate(`/app/products/information/${product?.id}`);
  };

  useEffect(() => {
    getCategories();
    getManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTransferCount(product?.id);
    getTransferCountListings(product?.id);
  }, [getTransferCount, getTransferCountListings, product.id]);

  useEffect(() => {
    return () => {
      resetProductPartsStates();
    };
  }, [resetProductPartsStates]);

  return (
    <Page className={classes.root} title={`${product?.name || ''}`}>
      <Box mt={2}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={8} md={8} xs={8}>
                <Box>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                  >
                    {product?.name ?? ''}
                  </Typography>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Price:
                    {product?.retail_price
                      ? ` ${formatCurrency(product?.retail_price)}`
                      : '₱0'}
                  </Typography>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    {`Available Stock(s): ${product?.available_count}`}
                  </Typography>
                  <Typography color="textSecondary" variant="h6">
                    Category: {product?.category ?? ''}
                  </Typography>
                  <Typography color="textSecondary" variant="h6">
                    SKU: {product?.sku ?? ''}
                  </Typography>

                  {transferCount && viewTransferListingStock && (
                    <Button
                      variant="outlined"
                      style={{ marginTop: '1rem' }}
                      color="secondary"
                      onClick={() => setIsOpen(true)}
                    >
                      {`On Transfer: ${transferCount} Item(s)`}
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item lg={4} md={4} xs={4}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  mt={1}
                  flexDirection="column"
                >
                  <Tooltip
                    title={'Click here to add a listing inside this product'}
                  >
                    <Button
                      hidden
                      variant="contained"
                      disabled={!canAddListing}
                      className={classes.addItemBtn}
                      onClick={onAddListingItemPress}
                    >
                      Add an item
                    </Button>
                  </Tooltip>
                  {canEditProductInformation ? (
                    <Button
                      style={{ width: 250 }}
                      disabled={isEmpty(categories) || isEmpty(manufacturers)}
                      onClick={onEditProductInformationPress}
                      variant="contained"
                    >
                      Edit Product Information
                    </Button>
                  ) : null}
                  <Button
                    color="primary"
                    style={{ marginTop: 10, width: 200 }}
                    disabled={isEmpty(product)}
                    onClick={() =>
                      navigate(`/app/flash-deals/add`, {
                        state: product
                      })
                    }
                    variant="outlined"
                  >
                    Add on Flash Deals
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <TransferStockListingDialog
        isOpen={isOpen}
        transferListings={transferCountListing}
        onHandleClose={() => setIsOpen(false)}
      />
    </Page>
  );
};

export default ProductDetailView;
