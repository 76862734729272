import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { slices, useAppSelector } from 'src/redux';
import { formatCurrency } from 'src/utils';
import { colors } from 'src/constants';
import { Link } from '@material-ui/core';
import { TransactionWithBal } from 'src/types';
import { AlternatingColorTableRow } from 'src/components/table';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open?: boolean;
  totalBalance?: string;
  handleClose: () => void;
}

const { selectors: transactionsSelectors } = slices.transaction;

export const TransactionsWithBalanceModal: FC<Props> = ({
  open = true,
  totalBalance,
  handleClose
}) => {
  const isMobile = useIsMobile();

  const transactionsWithBal: TransactionWithBal[] =
    useAppSelector(transactionsSelectors.selectTransactionsWithBal) || [];

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      open={open}
      onClose={handleClose}
    >
      <Box style={{ background: 'white' }}>
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              overflow: 'visible'
            }}
          >
            <Typography variant="h5" style={{ width: '13em' }}>
              Transactions with Balance
            </Typography>
            <CloseIcon color="secondary" onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          {isMobile ? (
            <Grid
              container
              style={{
                rowGap: '1em',
                maxHeight: '70vh',
                overflowY: 'auto'
              }}
            >
              {transactionsWithBal[0] &&
                transactionsWithBal.map((i) => (
                  <Grid
                    item
                    container
                    key={i.transaction_no}
                    style={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '1em'
                    }}
                    xs={12}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1em'
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', fontSize: '.9em' }}
                      >
                        Transaction No:
                      </Typography>
                      <Link
                        href={`/app/transaction/${i.transaction_no}`}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        style={{ fontSize: '.9em' }}
                      >
                        {i.transaction_no}
                      </Link>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1em'
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', fontSize: '.9em' }}
                      >
                        Branch:
                      </Typography>
                      <Typography style={{ fontSize: '.9em' }}>
                        {i.branch.join(', ')}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1em'
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', fontSize: '.9em' }}
                      >
                        Amount:
                      </Typography>
                      <Typography
                        style={{ color: colors.blue[600], fontSize: '.9em' }}
                      >
                        {formatCurrency(i.total_amount)}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1em'
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', fontSize: '.9em' }}
                      >
                        Paid:
                      </Typography>
                      <Typography
                        style={{ color: colors.purple[600], fontSize: '.9em' }}
                      >
                        {formatCurrency(i.total_payment)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{
                        marginLeft: '1em',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1em'
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 'bold', fontSize: '.9em' }}
                      >
                        Balance:
                      </Typography>
                      <Typography
                        style={{ color: colors.red[600], fontSize: '.9em' }}
                      >
                        {formatCurrency(i.balance)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Table
              stickyHeader
              style={{
                display: 'block',
                maxHeight: '50vh',
                overflowY: 'auto'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '4px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Transaction No.
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', padding: '4px' }}>
                    <Typography style={{ fontWeight: 'bold', width: '10em' }}>
                      Branch
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: '10em',
                      textAlign: 'center',
                      padding: '4px'
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Total Amount
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      padding: '6px',
                      width: '10em'
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      Amount Paid
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', padding: '4px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Balance Remaining
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsWithBal[0] &&
                  transactionsWithBal.map((i) => (
                    <AlternatingColorTableRow
                      hover
                      key={i.transaction_no}
                      style={{ height: '5em' }}
                    >
                      <TableCell style={{ padding: '4px' }}>
                        <Link
                          href={`/app/transaction/${i.transaction_no}`}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                        >
                          {i.transaction_no}
                        </Link>
                      </TableCell>
                      <TableCell
                        style={{
                          width: '10em',
                          textAlign: 'center',
                          padding: '6px'
                        }}
                      >
                        <Typography>{i.branch.join(', ')}</Typography>
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', padding: '6px' }}
                      >
                        <Typography style={{ color: colors.blue[600] }}>
                          {formatCurrency(i.total_amount)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', padding: '6px' }}
                      >
                        <Typography style={{ color: colors.purple[600] }}>
                          {formatCurrency(i.total_payment)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{ textAlign: 'center', padding: '4px' }}
                      >
                        <Typography style={{ color: colors.red[600] }}>
                          {formatCurrency(i.balance)}
                        </Typography>
                      </TableCell>
                    </AlternatingColorTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          <div
            style={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'end',
              columnGap: '1em'
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              Total Balance:
            </Typography>
            <Typography color="secondary">{totalBalance}</Typography>
          </div>
        </DialogContent>
      </Box>
    </Modal>
  );
};
