import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { cloneDeep, isEmpty, isEqual, uniq } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { FormPassCodeTransaction, Page, RemarksModal } from 'src/components';
import { allowedPaymentOnTermsField, localize } from 'src/constants';
import { CategoriesEnum } from 'src/enums';
import { useFormPasscodeDialog, useIsMount, useSnackBar } from 'src/hooks';
import { slices, useAppDispatch } from 'src/redux';
import {
  AddReplaceTransactionRequest,
  ChangeSrpPayload,
  CreateTransactionPaymentRequest,
  CreateTransactionRequest,
  CustomInputEvent,
  DeleteTransactionPaymentRequest,
  FixMeLater,
  GetTransactionDetailResponse,
  GetTransactionPaymentsResponse,
  GetTransactionsViaTransactionNoResponse,
  Rma,
  Transaction,
  TransactionFieldOptions,
  TransactionPayment,
  UpdateTransactionPaymentRequest,
  UpdateTransactionRemarksRequest
} from 'src/types';
import { formatCurrency } from 'src/utils';
import { AddItemTransactionModal } from '../components';
import {
  AddTransactionPaymentModal,
  TransactionDetailsAlertInfo,
  TransactionDetailsHeader,
  TransactionInfo,
  TransactionPaymentsCard,
  TransactionsCard,
  TransactionsRmaCard
} from './components';
import { SwapSerialNoTempList } from './components/SwapSerialNoTempList';

const { actions: transactionActions } = slices.transaction;
const { actions: transactionPaymentActions } = slices.transactionPayments;
const { actions: rmaActions } = slices.rma;

interface Accumulator {
  counts: { [key: string]: number };
  maxCount: number;
  mostCommon: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    PointerEvent: 'none'
  },
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },

  printButton: {
    marginBottom: 14
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  deleteBtn: {
    marginTop: 20
  },
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const TransactionInfoView = () => {
  const isFirstMount = useIsMount();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { transaction_no } = useParams();
  const {
    hideFormPasscodeDialog,
    validatePasscodeSuccess,
    validatePasscodeMessage,
    validatePasscodeError
  } = useFormPasscodeDialog();

  const [transactionPaymentResponse, setTransactionPaymentResponse] = useState<
    GetTransactionPaymentsResponse
  >();
  const [transactionDetailsResponse, setTransactionDetailsResponse] = useState<
    GetTransactionDetailResponse | undefined
  >();
  const [transactionInfo, setTransactionInfo] = useState<
    GetTransactionsViaTransactionNoResponse
  >();
  const [rmas, setRmas] = useState<Rma[]>();
  const [itemToVoid, setItemToVoid] = useState<Transaction | undefined>();
  const [isVisibleItemToVoidDialog, setIsVisibleItemToVoidDialog] = useState(
    false
  );
  const [itemToAddOrReplace, setItemToAddOrReplace] = useState<
    AddReplaceTransactionRequest | undefined
  >();
  const [addPaymentModalVisible, setAddPaymentModalVisible] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaymentDeleteLoading, setIsPaymentDeleteLoading] = useState<boolean>(
    false
  );
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [paymentToBeDeleted, setPaymentToBeDeleted] = useState<
    TransactionPayment
  >();

  const [isDeleteTransaction, setCallDeleteWholeTransaction] = useState<
    boolean
  >();

  useEffect(() => {
    if (validatePasscodeSuccess) {
      hideFormPasscodeDialog();
      setIsVisibleItemToVoidDialog(true);
      snackBar.show({ severity: 'success', message: validatePasscodeMessage });
    }

    if (!validatePasscodeSuccess && validatePasscodeError) {
      snackBar.show({ severity: 'error', message: validatePasscodeError });
    }
  }, [
    snackBar,
    hideFormPasscodeDialog,
    validatePasscodeSuccess,
    validatePasscodeMessage,
    validatePasscodeError
  ]);

  const getTransactionDetails = async () => {
    const response = unwrapResult(
      await dispatch(
        transactionActions.getTransactionDetailThunk(transaction_no)
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      setTransactionDetailsResponse(response?.originalData);
    }
  };

  const getTransactionInfo = async () => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionActions.getTransactionViaTransactionNo(transaction_no)
      ).finally(() => setIsLoading(false))
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      setTransactionInfo(response?.originalData);
    }
  };

  const getTransactionPayments = async () => {
    const response = unwrapResult(
      await dispatch(
        transactionPaymentActions.getTransactionPaymentsThunk(transaction_no)
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      setTransactionPaymentResponse(response?.originalData);
    }
  };

  const createTransactionPayment = async (
    data: CreateTransactionPaymentRequest
  ) => {
    if (!transaction_no) {
      return;
    }
    if (addPaymentModalVisible) {
      setAddPaymentModalVisible(false);
    }
    setIsPaymentLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionPaymentActions.createTransactionPaymentThunk(data)
      ).finally(() => setIsPaymentLoading(false))
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        severity: 'success',
        message: `${response.message} Balance left: ${formatCurrency(
          response?.originalData?.balance
        )}`
      });
      getTransactionPayments();
    } else {
      snackBar.show({
        severity: 'error',
        message: `${response?.errors?.payment_type?.[0] ??
          (response?.message || 'Error')}`
      });
    }
  };

  const onDeleteTransactionPayment = async (
    data: DeleteTransactionPaymentRequest
  ) => {
    if (!transaction_no) {
      return;
    }
    if (paymentToBeDeleted) {
      setPaymentToBeDeleted(undefined);
    }
    setIsPaymentDeleteLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionPaymentActions.deleteTransactionPaymentThunk(data)
      ).finally(() => setIsPaymentDeleteLoading(false))
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        severity: 'success',
        message: `${response.message} Balance left: ${formatCurrency(
          response?.originalData?.balance
        )}`
      });
      getTransactionPayments();
    } else {
      snackBar.show({
        severity: 'error',
        message: `${response.message || 'Error'}`
      });
    }
  };

  const getRmas = async () => {
    const response = unwrapResult(
      await dispatch(rmaActions.getRmasViaTransactionNoThunk(transaction_no))
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      setRmas(response?.originalData?.rmas);
    }
  };

  // NOTE: In case we want old printing again
  // const onPrintPress = () => {
  //   navigate(`/app/transaction/print`, {
  //     state: transactionInfo
  //   });
  // };

  const transactionItems = useMemo(() => transactionInfo?.items ?? [], [
    transactionInfo
  ]);

  const mostUsedBranch = useMemo(() => {
    const accumulator = transactionItems.reduce<Accumulator>(
      (acc, item) => {
        // Count the occurrences of each branch_name
        const branch = item.branch_name;
        if (typeof branch === 'string') {
          acc.counts[branch] = (acc.counts[branch] || 0) + 1;

          if (acc.counts[branch] > acc.maxCount) {
            acc.maxCount = acc.counts[branch];
            acc.mostCommon = branch;
          }
        }

        return acc;
      },
      { counts: {}, maxCount: 0, mostCommon: '' }
    );
    return accumulator.mostCommon;
  }, [transactionItems]);

  const isReceiptSRPByDefault = useMemo(() => {
    const mappedTerms = transactionPaymentResponse?.data?.map((x) => {
      if (allowedPaymentOnTermsField?.includes(x.payment_type || '')) {
        return x.payment_type || '';
      }
      return '';
    });
    const uniqTerms: string[] = uniq(mappedTerms);
    const termsWithSRP = ['PAYMONGO'];
    const hasSRPTerm = uniqTerms?.some((r) => termsWithSRP?.indexOf(r) >= 0);

    if (hasSRPTerm && transactionDetailsResponse?.data?.ecomm_order_ref_no) {
      return true;
    }
    return false;
  }, [transactionDetailsResponse, transactionPaymentResponse]);

  const onPrintPress = () => {
    // Transform data first.

    let transactionInfoWithOrderNo = {
      ...transactionInfo,
      order_no: transactionDetailsResponse?.data?.order_no || '',
      isSRPByDefault: isReceiptSRPByDefault,
      store_address: transactionDetailsResponse?.data?.store_address,
      ec_order_id: transactionDetailsResponse?.data?.ec_order_id
    };
    transactionInfoWithOrderNo.items = transactionInfoWithOrderNo?.items?.filter(
      (x) => x.isDeleted === 0
    );

    const receiptItemsOrder = [
      CategoriesEnum.GPU,
      CategoriesEnum.CPU,
      CategoriesEnum.Motherboard,
      CategoriesEnum.RAM,
      CategoriesEnum.SSD,
      CategoriesEnum.HDD,
      CategoriesEnum.PSU,
      CategoriesEnum.Casing,
      CategoriesEnum.Monitor
    ];

    transactionInfoWithOrderNo?.items?.sort(function(a, b) {
      if (receiptItemsOrder?.indexOf(a?.category_id || 0) <= -1) {
        return 1;
      }
      if (receiptItemsOrder?.indexOf(b?.category_id || 0) <= -1) {
        return -1;
      }
      return (
        receiptItemsOrder.indexOf(a?.category_id || 0) -
        receiptItemsOrder.indexOf(b?.category_id || 0)
      );
    });

    navigate(`/app/transaction/print`, {
      state: transactionInfoWithOrderNo
    });
  };

  const onCloseAddReplaceModal = () => {
    setItemToAddOrReplace(undefined);
  };

  const onDeletePress = () => {
    setIsVisibleItemToVoidDialog(true);
    setCallDeleteWholeTransaction(true);
  };

  const onVoidItem = (item: Transaction) => {
    setItemToVoid(item);
    setIsVisibleItemToVoidDialog(true);
  };

  const onReplaceItem = async (newItem: CreateTransactionRequest) => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionActions.replaceItemToTransactionNoThunk(newItem)
      ).finally(() => setIsLoading(false))
    );

    if (response?.success && response?.originalData) {
      getTransactionInfo();
      snackBar.show({
        message: response?.message || localize.ERR_API_UNKNOWN,
        severity: 'success'
      });
      return;
    }
    snackBar.show({
      message: response?.message || localize.ERR_API_UNKNOWN,
      severity: 'error'
    });
  };

  const onAddItem = async (newItem: CreateTransactionRequest) => {
    const newItemEcOrder = {
      ...newItem,
      ec_order_id: transactionDetailsResponse?.data?.ec_order_id
    };
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionActions.addItemToTransactionNoThunk(newItemEcOrder)
      ).finally(() => setIsLoading(false))
    );

    if (response?.success && response?.originalData) {
      getTransactionInfo();
      getTransactionPayments();
      snackBar.show({
        message: response?.message || localize.ERR_API_UNKNOWN,
        severity: 'success'
      });
      return;
    }
    setIsLoading(false);
    snackBar.show({
      message: response?.message || localize.ERR_API_UNKNOWN,
      severity: 'error'
    });
  };

  const onAddOrReplace = (data: AddReplaceTransactionRequest) => {
    onCloseAddReplaceModal();
    if (data?.mode === 'add') {
      onAddItem(data);
    }
    if (data?.mode === 'replace') {
      onReplaceItem(data);
    }
  };

  const onAddItemPress = async () => {
    setItemToAddOrReplace({ mode: 'add', transaction_no });
  };

  const onReplaceItemPress = async (toBeReplacedItem: Transaction) => {
    setItemToAddOrReplace({
      mode: 'replace',
      transaction_no,
      toBeReplacedItem
    });
  };

  const onTransactionRemarksChange = async (
    newDataRemarks: UpdateTransactionRemarksRequest
  ) => {
    const response = unwrapResult(
      await dispatch(
        transactionActions.updateTransactionRemarksThunk(newDataRemarks)
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        severity: 'success',
        message: 'transaction remarks update success'
      });
      dispatch(
        transactionActions.getTransactionViaTransactionNo(transaction_no)
      );
    } else {
      snackBar.show({
        severity: 'error',
        message: 'transaction remarks update failed'
      });
    }
  };

  const onTransactionDetailsCreateNew = async (
    newDate: GetTransactionDetailResponse
  ) => {
    const response = unwrapResult(
      await dispatch(
        transactionActions.createTransactionDetailThunk({
          ...newDate.data,
          transaction_no: transactionInfo?.transaction_no
        })
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        severity: 'success',
        message: 'transaction details update success'
      });
      getTransactionDetails();
      return;
    }
    if (!response?.success && response?.errors) {
      snackBar.show({
        severity: 'error',
        message: `${JSON.stringify(response?.errors || '')}`
      });
      return;
    }
    snackBar.show({
      severity: 'error',
      message: 'transaction details update failed'
    });
  };

  const onTransactionDetailsUpdate = async (
    newData: GetTransactionDetailResponse
  ) => {
    const response = unwrapResult(
      await dispatch(
        transactionActions.updateTransactionDetailThunk({
          ...newData.data,
          purchase_category: newData?.data?.purchase_category,
          type_of_shipping: newData?.data?.type_of_shipping,
          build_type: newData?.data?.build_type,
          type_of_sales_transaction: newData?.data?.type_of_sales_transaction
        })
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        severity: 'success',
        message: 'transaction remarks update success'
      });
      getTransactionDetails();
      return;
    }
    if (!response?.success && response?.errors) {
      snackBar.show({
        severity: 'error',
        message: `${JSON.stringify(response?.errors || '')}`
      });
      return;
    }
    snackBar.show({
      severity: 'error',
      message: 'transaction details update failed'
    });
  };

  const onTransactionInfoUpdate = async (
    newTransactionInfo: GetTransactionsViaTransactionNoResponse,
    newTransactionDetails: GetTransactionDetailResponse,
    newTransactionOptions?: TransactionFieldOptions
  ) => {
    const newTransactionPayload = {
      ...newTransactionDetails,
      data: {
        ...newTransactionDetails?.data,
        purchase_category:
          newTransactionOptions?.purchase_category ||
          transactionDetailsResponse?.data?.purchase_category,
        type_of_shipping:
          newTransactionOptions?.type_of_shipping ||
          transactionDetailsResponse?.data?.type_of_shipping,
        build_type:
          newTransactionOptions?.build_type ||
          transactionDetailsResponse?.data?.build_type,
        type_of_sales_transaction:
          newTransactionOptions?.type_of_sales_transaction ||
          transactionDetailsResponse?.data?.type_of_sales_transaction
      }
    };
    // remarks updated.
    if (
      transactionInfo?.transaction_remarks !==
      newTransactionInfo?.transaction_remarks
    ) {
      const newDataRemarks = {
        transaction_no: transactionInfo?.transaction_no,
        remarks: newTransactionInfo?.transaction_remarks
      };
      onTransactionRemarksChange(newDataRemarks);
    }

    // Meaning New, Should Create
    if (!newTransactionDetails?.data?.id) {
      onTransactionDetailsCreateNew(newTransactionDetails);
      return;
    }
    // else update only if changed / update
    if (!isEqual(transactionDetailsResponse, newTransactionPayload)) {
      onTransactionDetailsUpdate(newTransactionPayload);
      return;
    }
  };

  const onChangeARFieldText = useCallback(
    (event: CustomInputEvent, index: number) => {
      const { value } = event.target;
      const clonedPaymentResponse = cloneDeep(transactionPaymentResponse);
      if (clonedPaymentResponse?.data) {
        clonedPaymentResponse.data[index].acknowledgement_receipt_no = value;
      }
      setTransactionPaymentResponse(clonedPaymentResponse);
    },
    [transactionPaymentResponse]
  );

  const onSaveARField = useCallback(
    (e: FixMeLater, item: TransactionPayment) => {
      e.stopPropagation();
      const newPaymentData: UpdateTransactionPaymentRequest = {
        id: item?.id,
        acknowledgement_receipt_no: item?.acknowledgement_receipt_no
      };
      setIsPaymentLoading(true);
      dispatch(
        transactionPaymentActions.updateTransactionPaymentThunk(newPaymentData)
      ).finally(() => {
        setIsPaymentLoading(false);
        getTransactionPayments();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const onHandleChangeSrp = useCallback(
    async (params?: ChangeSrpPayload) => {
      try {
        const response = unwrapResult(
          await dispatch(transactionActions?.changeTransactionSrpThunk(params))
        );

        if (response?.success) {
          snackBar.show({ severity: 'success', message: response?.message });
          getTransactionDetails();
          getTransactionInfo();
          getTransactionPayments();
        } else {
          snackBar.show({
            severity: 'error',
            message: 'Something went wrong please try again later'
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      dispatch,
      getTransactionDetails,
      getTransactionInfo,
      getTransactionPayments,
      snackBar
    ]
  );

  useEffect(() => {
    if (isFirstMount) {
      getTransactionInfo();
      getTransactionDetails();
      getRmas();
      getTransactionPayments();
    }
    if (validatePasscodeSuccess) {
      getTransactionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstMount, validatePasscodeSuccess]);

  return (
    <Page title="Transaction Details" className={clsx(classes.root)}>
      <Container maxWidth={false}>
        <TransactionDetailsHeader
          isLoading={isLoading}
          onPrintPress={onPrintPress}
        />
        <TransactionInfo
          isLoading={isLoading}
          onUpdate={onTransactionInfoUpdate}
          transactionInfo={transactionInfo}
          transactionDetails={transactionDetailsResponse}
          transactionPayments={transactionPaymentResponse?.data || []}
        />
        <TransactionDetailsAlertInfo />

        <TransactionsCard
          isLoading={isLoading}
          transactionInfo={transactionInfo}
          transactionDetails={transactionDetailsResponse}
          onVoidItem={onVoidItem}
          onAddItemPress={onAddItemPress}
          onReplaceItemPress={onReplaceItemPress}
          onChangeSrp={(params?: ChangeSrpPayload) => onHandleChangeSrp(params)}
          // setTransactionToRma={setTransactionToRma}
          getDeleteTransactionInfo={() => setCallDeleteWholeTransaction(false)}
        />

        <SwapSerialNoTempList
          transactionItems={transactionItems}
          transactionNo={transactionInfo?.transaction_no}
          getTransactionInfo={getTransactionInfo}
        />

        {isLoading ? null : (
          <TransactionPaymentsCard
            isLoading={isPaymentLoading}
            data={transactionPaymentResponse}
            onDeletePayment={(data) => setPaymentToBeDeleted(data)}
            onAddPaymentPress={() => setAddPaymentModalVisible(true)}
            onSaveARField={onSaveARField}
            onChangeARFieldText={onChangeARFieldText}
          />
        )}

        {rmas && rmas?.length > 0 ? <TransactionsRmaCard rmas={rmas} /> : null}

        <Button
          className={classes.deleteBtn}
          startIcon={<DeleteIcon />}
          onClick={onDeletePress}
          color="secondary"
          variant="contained"
        >
          Delete Transaction
        </Button>
      </Container>

      <AddItemTransactionModal
        data={itemToAddOrReplace}
        mostUsedBranch={mostUsedBranch}
        onAddOrReplace={onAddOrReplace}
        visible={itemToAddOrReplace ? true : false}
        onHandleClose={onCloseAddReplaceModal}
      />

      <AddTransactionPaymentModal
        visible={addPaymentModalVisible}
        onHandleClose={() => setAddPaymentModalVisible(false)}
        transactionNo={transaction_no}
        onApplyPress={createTransactionPayment}
        transactionPayments={transactionPaymentResponse}
      />

      <RemarksModal
        loading={isPaymentDeleteLoading}
        data={paymentToBeDeleted}
        fieldName="Remarks / Reason for deletion"
        visible={paymentToBeDeleted ? true : false}
        onDeletePress={onDeleteTransactionPayment}
        onHandleClose={() => setPaymentToBeDeleted(undefined)}
        title={`Delete a payment (${formatCurrency(
          paymentToBeDeleted?.amount
        )} - ${paymentToBeDeleted?.payment_type})`}
      />

      <FormPassCodeTransaction
        isVisible={isVisibleItemToVoidDialog}
        onClose={() => setIsVisibleItemToVoidDialog(false)}
        itemToVoid={itemToVoid}
        transactionInfo={transactionInfo}
        transaction_no={transaction_no}
        getTransaction={() => getTransactionInfo()}
        getTransactionPayments={getTransactionPayments}
        setItemVoid={() => setItemToVoid(undefined)}
        isDeleteTransaction={isDeleteTransaction}
      />
    </Page>
  );
};

export default TransactionInfoView;
