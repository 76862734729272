import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectSales = (state: RootState) => state.sales;

const selectAllSales = createSelector(selectSales, (state) => state.sales);
const selectSalesResponse = createSelector(
  selectSales,
  (state) => state.salesResponse
);
const selectSalesResponseComputation = createSelector(
  selectSales,
  (state) => state.salesComputation
);
const selectAllSalesFilter = createSelector(
  selectSales,
  (state) => state.salesFilter
);
const selectSalesColumns = createSelector(
  selectSales,
  (state) => state.salesColumns
);
const selectSalesLoadingNextPage = createSelector(
  selectSales,
  (state) => state.isNextPageLoading
);

export const selectors = {
  selectAllSales,
  selectAllSalesFilter,
  selectSalesResponse,
  selectSalesResponseComputation,
  selectSalesColumns,
  selectSalesLoadingNextPage
};
